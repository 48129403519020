import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

// page & layout imports
import Homepage from './pages/Homepage';
import Knoop from './pages/DeKnoop';
import SiteHeader from './components/SiteHeader';
import KnoopHeader from './components/KnoopHeader';
import SiteTail from './components/SiteTail';
import DeGroep from './pages/DeGroep';
import Takken from './pages/TakkenEnLeiding';
import Tak from './pages/Tak';
import Verhuur from './pages/Verhuur';
import Contact from './pages/Contact';
import LidWorden from './pages/LidWorden';
import VerhuurInfo from './components/VerhuurInfo';
import Login from './components/login';
import Event from './pages/EventDetails';
import Inschrijven from './pages/inschrijven';
import Calender from './pages/leidingscalender';
import { Helmet } from 'react-helmet';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './components/AuthContext';
import C from './components/sliderWidget/SliderWidget';
const client = new ApolloClient({
  uri: 'https://api.scouts121.be/graphql',
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <Router>
          <Helmet>
            <title>Scouts 121</title>
            <meta name="description" content="this is a description" />
          </Helmet>
          <div className="App">
            <SiteHeader />
            <Switch>
              <Route exact path="/">
                <Homepage />
              </Route>
              <Route exact path="/degroep">
                <DeGroep />
              </Route>
              <Route exact path="/verhuur">
                <Verhuur />
              </Route>
              <Route exact path="/verhuur/:id">
                <VerhuurInfo />
              </Route>
              <Route exact path="/takken">
                <Takken />
              </Route>
              <Route exact path="/takken/:id">
                <Tak />
              </Route>
              <Route path="/deknoop/:id">
                <KnoopHeader />
                <Knoop />
              </Route>
              <Route path="/deknoop/">
                <KnoopHeader />
              </Route>
              <Route path="/Inschrijving_Scouts121/">
                <LidWorden />
              </Route>
              <Route path="/l/">
                <C />
              </Route>
              <Route path="/inschrijven/">
                <Inschrijven />
              </Route>
              <Route path="/contact/">
                <Contact />
              </Route>
              <Route path="/evenementen/:id">
                <Event />
              </Route>
              <Route path="/login/">
                <Login />
              </Route>
              <PrivateRoute path="/calenderleiding/" component={Calender} />
            </Switch>
            <SiteTail />
          </div>
        </Router>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
