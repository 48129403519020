import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import ContactForm from '../components/contactform';
import { Helmet } from 'react-helmet';

export default function Homepage() {
  return (
    <div>
      <Helmet>
        <title>Contact | Scouts 121 Oude-God Mortsel</title>
        <meta
          name="description"
          content="de E-mailadressen en contact info voor scouts 121 Oude-God Mortsel"
        />
        <meta
          name="keywords"
          content="contact, contacteren, e-mail, scouts121, 121, scouts"
        />
        <meta name="HandheldFriendly" content="true" />
      </Helmet>
      <ContactForm />
    </div>
  );
}
