import React from 'react';

export default function inschrijven() {
  return (
    <div className="grid-row-2 grid-col-4">
      <iframe
        allowfullscreen=""
        frameborder="0"
        height="900"
        src="https://groepsadmin.scoutsengidsenvlaanderen.be/groepsadmin/lidworden?groep=A3121G"
        width="100%"
      ></iframe>
    </div>
  );
}
