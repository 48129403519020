import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { format, parseISO, startOfDay } from 'date-fns';

const EVENTS = gql`
  query GetEvenementen {
    leidingevents {
      data {
        id
        attributes {
          title
          datum
          beschrijving
          foto {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default function Evenementen() {
  // Use the useQuery hook to fetch the data
  const { loading, error, data } = useQuery(EVENTS);

  // Handle loading and error states
  if (loading) return <p>Loading takken...</p>;
  if (error) return <p>Error bij ophalen takken</p>;

  return (
    <div className={`large6`}>
      <div className={`eventlarge6 cards`}>
        {data.leidingsevents.map((leidingsevent) => (
          <div key={leidingsevent.id}>
            {leidingsevent.attributes.foto &&
              leidingsevent.attributes.foto.data && (
                <img
                  src={`https://api.scouts121.be${leidingsevent.attributes.foto.data.attributes.url}`} // Adjusted path
                  alt={leidingsevent.attributes.title}
                />
              )}
            <div className="eventGrid">
              <div>
                <div className="centered">
                  <p>
                    {format(parseISO(leidingsevent.attributes.datum), 'MMM')}
                  </p>
                  <h3>
                    {format(parseISO(leidingsevent.attributes.datum), 'd')}
                  </h3>
                </div>{' '}
              </div>
              <div className="grid-col-2">
                <div className="centered">
                  <Link to={`/evenementen/${leidingsevent.id}`}>
                    <p className="link">{leidingsevent.attributes.title}</p>
                  </Link>
                  <p className="link">{leidingsevent.attributes.description}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
