import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Helmet } from 'react-helmet';

export default function Homepage() {
  return (
    <div className="textelement">
      <Helmet>
        <title>De Groep | Scouts 121 Oude-God Mortsel</title>
        <meta
          name="description"
          content="Scouts 121 Scouts 121 bestaat sinds 2014 en is ontstaan uit de fusie van twee oudere scoutsen, namelijk scouts 115 en scouts 144. Voordat deze fusie voltrokken kon worden, werd er echter jaren gewerkt aan de voorbereiding: vergaderen, overleggen, stemmen, informatie inwinnen,... Wil je graag meer weten over het ontstaan van scouts 121? Lees dan verder! Ook de bouw van onze"
        />
        <meta
          name="keywords"
          content="contact, contacteren, e-mail, scouts121, 121, scouts"
        />
        <meta name="HandheldFriendly" content="true" />
      </Helmet>
      <h1>De Groep</h1>
      <div>
        {' '}
        <h2>Scouts 121</h2>
        <p>
          Scouts 121 bestaat sinds 2014 en is ontstaan uit de fusie van twee
          oudere scoutsen, namelijk scouts 115 en scouts 144. Voordat deze fusie
          voltrokken kon worden, werd er echter jaren gewerkt aan de
          voorbereiding: vergaderen, overleggen, stemmen, informatie
          inwinnen,...
        </p>
        <p>
          Ook de bouw van onze lokalen was geen sinecure en vroeg om de
          participatie van vele grote en sommige, iets kleinere vrijwilligers.
          ​Het resultaat mag er echter zijn en daar hebben we een hele hoop
          mensen voor te danken, vergeet zeker niet eens een blik te werpen op
          de bouwplaat bij het binnenkomen!
        </p>
      </div>
      <div>
        <h2>Uniform</h2>
        <p>
          Zondagochtend, u zoekt het uniform van uw kleine spruit? Maar hoe zag
          dat er ook al weer uit? Ook dit jaar verwachten we vanuit de
          leidingsploeg dat iedereen ! in uniform naar de vergadering komt. Bij
          ons bestaat het uniform uit 3 delen:
        </p>
        <ul>
          <li>
            <p>Das</p>
          </li>
          <li>
            <p>Hemd</p>
          </li>
          <li>
            <p>Slechte broek</p>
          </li>
        </ul>
        <p>
          Dit geldt voor iedereen, behalve voor de Kapoenen. Zij zijn nog niet
          verplicht een hemd te dragen. (Maar het mag natuurlijk wel) Bij de
          Hopper vind je mooie truien die ook deel uitmaken van het uniform,
          maar niet verplicht zijn.
        </p>{' '}
        <h3>Waar vind ik zo'n das?</h3>
        <p>
          Dassen zijn verplicht van bij de kapoenen. Zo kunnen we gemakkelijk
          iedereen herkennen. Ook in het openbaar word je op deze manier beter
          herkend en erkend. We dragen als Scouts 121 een zwarte das met groene
          band; zwarte binnenkant en groene boord. Ze zijn te koop bij de
          groepsleiding voor €10/stuk. Zet er direct je naam in zodat je je das
          gemakkelijk terugvindt, moest je hem kwijt zijn en hij bij de verloren
          voorwerpen belandt.
        </p>
        <h3>En dat hemd?</h3>
        <p>
          We dragen geen roze geruite hemden maar beige scoutshemden. Met dat
          hemd maak je deel uit van een veel grotere groep scoutsen. Iedereen
          over de hele wereld die in de scouts zit heeft zo een hemd, jij dus
          ook. Je kan het kopen in de Hopper winkel voor €35 voor een kindermaat
          en €38 voor een volwassenmaat. Kentekens op hemd Op dit hemd komen ook
          alle kentekens, zodat je helemaal in orde bent. We geven graag nog een
          tip; voor de welka's en jojo's koop je het hemd best een beetje op de
          groei...
        </p>
        <h3>Waarom die slechte broek?</h3>
        <p>
          Als scout moet je een broek hebben die vuil mag worden. Een broek die
          je kan oplappen als ze scheurt. Zo kan je die mooie jeans nog dragen
          voor school en familiefeesten. Je mag zelf kiezen wat voor SLECHTE
          broek je draagt; kort, lang, licht, donker,... Dat maakt allemaal niet
          uit, zolang het maar niet uitmaakt of ze scheurt of vuil wordt!
        </p>
      </div>
    </div>
  );
}
