import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import logoVlaanderen from '../logos/Vlaanderen.png';
import logoHopper from '../logos/hopper.svg';
import logoSGV from '../logos/sgv.svg';
import logoscouts121 from '../logos/logo.png';

const Footer = () => {
  const { user, logout } = useContext(AuthContext);
  return (
    <footer className="footer1">
      <div className="textelement container1">
        <div className="section1">
          <img src={logoscouts121} alt="Company Logo" className="logo1" />
          <p>
            Edegemsestraat 115b 2640 Mortsel<p>groepsleiding@scouts121.be</p>
          </p>
        </div>
        <div className="section1">
          <a
            href="https://www.facebook.com/scouts121OudeGod/"
            className="link1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="button1">
              <FaFacebook size={20} />
            </button>
          </a>
          <a
            href="https://www.instagram.com/scouts121"
            className="link1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="button1">
              <FaInstagram size={20} />
            </button>
          </a>
        </div>

        <div className="section1">
          <img src={logoSGV} alt="Logo 1" className="logo1" />
        </div>
        <div className="section1">
          <img src={logoHopper} alt="Logo 2" className="smallLogo1" />
          <img src={logoVlaanderen} alt="Logo 3" className="smallLogo1" />
        </div>
        <div className="section1">
          <Link to="/login" className="link1">
            <button className="loginButton1">Login voor leiding</button>
          </Link>
          <button className="loginButton1" onClick={logout}>
            Logout
          </button>
          <p>ontworpen & ontwikkeld door </p>
          <a href="https://github.com/ThreeLittleDucklings" className="link1">
            <p>@ThreeLittleDucklings</p>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
