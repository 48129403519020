import React, { useEffect } from 'react';

const Facebook = () => {
  useEffect(() => {
    // Dynamically load the Facebook SDK script
    const script = document.createElement('script');
    script.src =
      'https://connect.facebook.net/nl_NL/sdk.js#xfbml=1&version=v20.0';
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';
    script.nonce = 'rPtsnkyY';
    document.body.appendChild(script);

    // Cleanup function to remove the script
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className=" grid-row-2 overflow">
      <div
        className="fb-page"
        data-href="https://www.facebook.com/scouts121OudeGod"
        data-show-posts="true"
        data-width="600"
        data-height="1100"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          cite="https://www.facebook.com/scouts121OudeGod"
          className="fb-xfbml-parse-ignore"
        >
          <a href="https://www.facebook.com/scouts121OudeGod">Scouts 121</a>
        </blockquote>
      </div>
    </div>
  );
};

export default Facebook;
