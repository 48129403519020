import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
const GET_EVENT = gql`
  query GetEvent($id: ID!) {
    event(id: $id) {
      data {
        id
        attributes {
          title
          datum
          description
          thumbnail {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default function DeLeiding() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_EVENT, {
    variables: { id: id },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  const event = data.event.data;

  return (
    <div className="textelement">
      <Helmet>
        <title>{event.attributes.title} | Scouts 121 Oude-God Mortsel</title>
        <meta name="description" content={event.attributes.description} />
        <meta name="keywords" content="evenement, scouts121, 121, scouts" />
        <meta name="HandheldFriendly" content="true" />
      </Helmet>
      <h1>{event.attributes.title}</h1>
      <h1>{event.attributes.datum}</h1>
      <div className="leiding-card">
        <ReactMarkdown children={event.attributes.description} />
      </div>
    </div>
  );
}
