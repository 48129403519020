import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    category: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post('https://api.scouts121.be/contactform', formData)
      .then((response) => {
        alert('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
          category: '',
        });
      })
      .catch((error) => {
        alert('Failed to send message.');
        console.error('There was an error!', error);
      });
  };

  // Inline styles
  const formStyle = {
    padding: '20px',
    maxWidth: '600px',
    margin: '0 auto',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '5px',
  };

  const inputStyle = {
    display: 'block',
    width: '100%',
    padding: '8px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  };

  const textareaStyle = {
    display: 'block',
    width: '100%',
    padding: '8px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    minHeight: '100px',
  };

  const selectStyle = {
    display: 'block',
    width: '100%',
    padding: '8px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  };

  const buttonStyle = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#007BFF',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <h2>Contacteer ons</h2>
      <div className="textelement">
        <h1>e-mailadressen</h1>
        <h3>groepsleiding</h3>
        <p>groepsleiding@scouts121.be</p>
        <h3>verhuur</h3>
        <p>verhuur@scouts121.be</p>
        <h3>kapoenen</h3>
        <p>kapoenen@scouts121.be</p>
        <h3>welka's</h3>
        <p>welkas@scouts121.be</p>
        <h3>jogi's</h3>
        <p>jogis@scouts121.be</p>
        <h3>givers</h3>
        <p>givers@scouts121.be</p>
        <h3>jins</h3>
        <p>jins@scouts121.be</p>
      </div>
      <form onSubmit={handleSubmit} style={formStyle}>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="name" style={labelStyle}>
            Name:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            style={inputStyle}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="email" style={labelStyle}>
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            style={inputStyle}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="subject" style={labelStyle}>
            Subject:
          </label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
            style={inputStyle}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="message" style={labelStyle}>
            Message:
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            style={textareaStyle}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="category" style={labelStyle}>
            Category:
          </label>
          <select
            id="category"
            name="category"
            value={formData.category}
            onChange={handleChange}
            required
            style={selectStyle}
          >
            <option value="">Select a category</option>
            <option value="Algemene vraag">Algemene vraag</option>
            <option value="Kapoenen">Kapoenen</option>
            <option value="Welka's">Welka's</option>
            <option value="Jogi's">Jogi's</option>
            <option value="Givers">Givers</option>
            <option value="Jins">Jins</option>
            <option value="Verhuur">Verhuur</option>
          </select>
        </div>
        <button type="submit" style={buttonStyle}>
          Send
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
