import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './AuthContext'; // Import AuthContext

import logoscouts121 from '../logos/logo.png';
import Trooper from './trooper.png';
import logosgv from '../logos/sgv.svg';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const { user } = useContext(AuthContext); // Access the user from AuthContext

  const handleLinkClick = () => {
    setMenuOpen(false); // Close the menu
  };

  const headerImageUrl =
    'https://images.unsplash.com/photo-1503435980610-a51f3ddfee50?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

  // Function to handle clicks outside of the sidebar
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false); // Close the menu if clicked outside
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the sidebar
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <img className="headerimg" src={headerImageUrl} alt="Header Background" />
      <div className="logo">
        <img className="headerimg" src={logoscouts121} alt="Logo" />
      </div>
      <div className="logosgv">
        <img className="headerimg" src={logosgv} alt="Logo" />
      </div>

      <nav className="nav-bar" ref={menuRef}>
        <input
          className="input"
          type="checkbox"
          id="menu-toggle"
          checked={isMenuOpen}
          onChange={() => setMenuOpen(!isMenuOpen)}
        />
        <label htmlFor="menu-toggle" className="menu-icon">
          &#9776;
        </label>
        <ul className="nav">
          <li>
            <Link to="/" onClick={handleLinkClick}>
              home
            </Link>
          </li>
          <li>
            <Link to="/degroep" onClick={handleLinkClick}>
              de groep
            </Link>
          </li>
          <li>
            <Link to="/deknoop" onClick={handleLinkClick}>
              de knoop
            </Link>
          </li>
          <li>
            <Link to="/takken" onClick={handleLinkClick}>
              takken & leiding
            </Link>
          </li>
          <li>
            <Link to="/Inschrijving_Scouts121" onClick={handleLinkClick}>
              lid worden
            </Link>
          </li>
          <li>
            <Link to="/verhuur" onClick={handleLinkClick}>
              verhuur
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={handleLinkClick}>
              contact
            </Link>
          </li>
          {user && ( // Conditionally render based on authentication
            <li>
              <Link to="/calenderleiding" onClick={handleLinkClick}>
                calender
              </Link>
            </li>
          )}
          <li>
            <a href="https://www.trooper.be/nl/trooperverenigingen/scouts121/">
              <img src={Trooper} className="imgTrooper" alt="Trooper" />
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
