import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Helmet } from 'react-helmet';
const VERHUUR_QUERY = gql`
  query GetVerhuurCategorieen {
    verhuurcategories {
      data {
        id
        attributes {
          type
          beschrijving
        }
      }
    }
  }
`;

export default function Verhuur() {
  // Use the useQuery hook to fetch the data
  const { loading, error, data } = useQuery(VERHUUR_QUERY);

  // Handle loading and error states
  if (loading) return <p>Loading takken...</p>;
  if (error) return <p>Error bij ophalen takken</p>;

  // Extract takken from the response data
  const verhuurCategorieen = data.verhuurcategories.data;

  return (
    <div className="knoop-header">
      <Helmet>
        <title>Verhuur | Scouts 121 Oude-God Mortsel</title>
        <meta
          name="description"
          content="Wel eens nood gehad aan een hele grote pot die je zelf niet in de kast hebt staan? Of voor je tuinfeest op zoek geweest naar een grote tent? Je wil misschien graag op kamp, maar je hebt geen tenten of sjorhout? Geen nood, Scouts 121 helpt je daar graag mee verder. Scouts 121 beschikt over een uitgebreid gamma van materiaal. Alles wat we aanbieden, vind je hieronder. Met der tijd zou het wel eens kunnen zijn dat het aanbod wat verandert en uitbreidt. Heb je toch een specifieke vraag over iets bepaald waar je naar op zoek bent? Aarzel dan niet ons te contacteren op groepsleiding@scouts.com."
        />
        <meta
          name="keywords"
          content="contact, contacteren, e-mail, scouts121, 121, scouts"
        />
        <meta name="HandheldFriendly" content="true" />
      </Helmet>
      <div className="verhuur verhuurGrid">
        {verhuurCategorieen.map((verhuurcategory) => (
          <div className="g1-card" key={verhuurcategory.id}>
            <Link to={`/Verhuur/${verhuurcategory.id}`}>
              <h2 className="link">{verhuurcategory.attributes.type}</h2>
            </Link>
            <p>{verhuurcategory.attributes.beschrijving}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
